<template>
  <div>
    <b-list-group-item
      class="entity"
      @contextmenu.prevent="$refs.menu.open"
    >
      <h6 class="text-primary">
        {{ component.name }}
      </h6>
      <div v-if="component.description">
        <div v-sanitized-html="component.description" />
      </div>
      <div v-if="component.release_status">
        <b-row>
          <b-col cols="9">
            <b-progress :max="100" height="2rem">
              <b-progress-bar :value="progressValue" variant="success">
                <span>{{ component.release_status }}</span>
              </b-progress-bar>
            </b-progress>
          </b-col>
          <b-col cols="3">
            <b-form-select
              id="ddlStatus"
              v-model="component.release_status"
              :options="releaseStatus"
            />
          </b-col>
        </b-row>
        <b-button
          variant="outline-primary"
          target="_blank"
          size="sm"
          @click="routeToEntity"
        >
          Open in Ontology
          <feather-icon
            icon="ExternalLinkIcon"
            class="font-small-2 mr-50"
          />
        </b-button>

      </div>
    </b-list-group-item>
  </div>
</template>

<script>
import VueContext from 'vue-context'
import { BLink } from 'bootstrap-vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ListGroupItemEntity',
  components: {
  },
  props: {
    component: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      menuData: [
        { icon: 'ChevronRightIcon', text: 'Open in Ontology', method: () => this.routeToEntity() },
        { icon: 'ChevronsRightIcon', text: 'Open parent in Ontology', method: () => this.routeToParentEntity() },
      ],
    }
  },
  computed: {
    ...mapGetters({
      releaseStatus: 'constants/releaseAllocationStatus',
    }),
    entityType() {
      return this.component.cpt_labels ? this.component.cpt_labels.filter(e => e !== 'Component') : []
    },
    progressValue() {
      if (this.component.release_status) {
        // Equally divide the progress bar by however many release status we have
        // Use that value as a 'section' of the progress bar to avoid entering values manually
        const progressValue = 100 / this.releaseStatus.length

        switch (this.component.release_status) {
        case 'Assigned to Release':
          return progressValue
        case 'Procured':
          return progressValue * 2
        case 'Initiation Completed':
          return progressValue * 3
        case 'Preliminary Design Completed':
          return progressValue * 4
        case 'Detailed Design Completed':
          return progressValue * 5
        case 'Ready for Build':
          return progressValue * 6
        case 'TRR Completed':
          return progressValue * 7
        case 'SIT Completed':
          return progressValue * 8
        case 'UAT Completed':
          return progressValue * 9
        case 'Ready for SWIT':
          return progressValue * 10
        case 'SWIT Complete':
          return progressValue * 11
        case 'Production':
          return progressValue * 12
        default:
          return progressValue
        }
      } else {
        return 10
      }
    },
  },
  methods: {
    routeToEntity() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you wish to leave this page? Any unsaved changes will be lost.', {
          title: 'Open Entity',
          size: 'sm',
          okVariant: 'success',
          okTitle: 'Open Entity',
          cancelTitle: 'Dismiss',
          cancelVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$router.push(
              {
                name: 'domain_ontology_focus',
                query: { focus: this.component.id },
              },
            )
          }
        })
    },
    routeToParentEntity() {
      this.$router.push(
        {
          name: 'domain_ontology_focus',
          query: { focus: this.component.parent },
        },
      )
    },
    entityLabel(data) {
      // Depending on the label of the Entity, return a different icon
      switch (data) {
      case 'Resource':
        return 'BookOpenIcon'
      case 'Function':
        return 'ZapIcon'
      case 'Performer':
        return 'UserIcon'
      case 'Standard':
        return 'FileTextIcon'
      case 'Reference':
        return 'EyeIcon'
      case 'Agreement':
        return 'PenToolIcon'
      case 'Capability':
        return 'CheckSquareIcon'
      case 'Activity':
        return 'ActivityIcon'
      case 'Service':
        return 'ToolIcon'
      case 'System':
        return 'ServerIcon'
      case 'Objective':
        return 'TargetIcon'
      default:
        return ''
      }
    },
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-context.scss';

.btn-flat-primary:focus {
  background-color: unset;
}

.entity {
  background-color: rgba(22, 29, 49, 0.02) !important;
  border: 1px dashed;
  border-left: 0;
  border-right: 0;
}

.entity:hover {
  cursor: pointer;
  background-color: rgba(22, 29, 49, 0.05) !important;
}

.dark-layout {
  .entity {
    background-color: rgba(22, 29, 49, 0.3) !important;
  }

  .entity:hover {
    background-color: rgba(22, 29, 49, 1) !important;
  }
}
</style>
